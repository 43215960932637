import ng from 'angular';

// @ngInject
function states($stateProvider, nsUtilProvider) {
  /*
    Chose to do several ng-if statements inside the header and
    footer templates. In the future if and when the business asks to
    show the links that have been hidden by dda we can remove all of
    the ng-if statements.
  */

  $stateProvider
    .state('login', {
      redirectTo: '.main',
      url: '?reason',
      templateUrl: 'apps/login/templates/acp-login-layout.ng.html',
      controller: 'AcpSectionLoginCtrl',
      data: {
        permissions: {
          only: 'password_unauthed',
          except: 'isVariantSunset',
          redirectTo: {
            password_unauthed: 'dashboard',
            isVariantSunset: 'sunset-tenant.login'
          }
        }
      },
      params: {
        backButtonEnabled: null,
        reason: null
      },
      resolve: {
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.apps.login" */ 'apps/login'
          ).then($ocLazyLoad.loadModule);
        },
        $hideAppBarLogo: /* @ngInject */ function (acpFrontendConfigModel) {
          return acpFrontendConfigModel.get(
            acpFrontendConfigModel.keys.HIDE_APPBAR_LOGO,
            false
          );
        },
        pageTitle: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(nsUtilProvider.getPageTitle('apps/login'));
        },
        pageH1: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('apps/login', 'page-h1')
          );
        },
        pageMetaDescription: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('apps/login', 'meta-description')
          );
        }
      }
    })
    .state('login.main', {
      url: '/login',
      templateUrl: 'apps/login/templates/acp-login-form.ng.html'
    })
    .state('login-authorize', {
      url: '/login/authorize',
      reloadOnSearch: false,
      template:
        '<div layout="column" layout-align="center center" layout-fill><ns-progress-circular class="md-primary" ns-mode="indeterminate"></ns-progress-circular></div>',
      controller: /* @ngInject */ function (
        $state,
        $window,
        hash,
        acpAuthModel
      ) {
        // Immediately, we want to reload the state, redacting the hash so it is not
        // exposed in the URL. We use {location: 'replace'} so it is removed from browser
        // history.  For some reason, if we replace the hash with '', then it reloads the
        // whole page. We have to replace it with a string of some sort in order to prevent
        // the reload of the page.
        $state
          .go('.', { '#': '$' }, { location: 'replace' })
          .then(acpAuthModel.logout.bind(null, true))
          .catch(ng.noop)
          .then(function () {
            var params = ng.fromJson($window.atob(hash));

            if (params.redirect_to) {
              acpAuthModel.setPostLoginRedirect(params.redirect_to);
            }

            return acpAuthModel.authAccessToken(params.access_token);
          })
          .catch(function () {
            $state.go('login');
          });
      },
      params: {
        '#': null
      },
      resolve: {
        hash: function ($location, $state) {
          'ngInject';
          // Save the hash for later use
          return $location.hash() || $state.params['#'];
        }
      }
    })
    .state('login.reset-password', {
      url: '/login/reset-password',
      templateUrl: 'apps/login/templates/acp-reset-password.ng.html'
    })
    .state('login.set-security-question', {
      url: '/login/set-security-question',
      templateUrl: 'apps/login/templates/acp-set-security-question.ng.html'
    })
    .state('login.idq', {
      url: '/login/idq',
      templateUrl: 'apps/login/templates/acp-idq.ng.html'
    })
    .state('login.ooba', {
      url: '/login/ooba',
      params: {
        oobaCode: null
      },
      templateUrl: 'apps/login/templates/acp-set-ooba.ng.html'
    })
    .state('login.call-cs', {
      url: '/login/call-cs',
      templateUrl: 'apps/login/templates/acp-call-cs.ng.html'
    })
    .state('login.biometrics', {
      controller: 'AcpBiometricsEnrollmentCtrl',
      template: ''
    })
    .state('login.acquisition', {
      url: '/login/acquisition',
      controller: /* @ngInject */ function ($state, acpAcquisitionModel) {
        var inAppBrowserOptions = {
          location: 'yes',
          closeButtonText: 'Already have an account? <u>Login</u>',
          headerText: '',
          toolbarColor: '#ffffff',
          headerTextColor: '#0092bc'
        };
        $state.go('login.main').then(function () {
          acpAcquisitionModel.acquireNewCustomer(null, inAppBrowserOptions);
        });
      }
    });
}

export default states;
